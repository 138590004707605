import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import {
  DashboardOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const { SubMenu } = Menu;

const SideMenuExecutive = ({ userRole }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState([]);

  const handleOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    navigate('/');
  };

  const menuConfig = {
    'Dashboard': {
      visibleTo: [0, 1, 2, 3, 4],
      // icon: <DashboardOutlined />,
      link: '/dashboard',
    },
    'Part Timers': {
      visibleTo: [0, 1, 2],
      // icon: <UserAddOutlined />,
      subMenu: {
        // 'Create': {
        //   visibleTo: [0,1,2],
        //   link: '/employee-create',
        // },   
        'List': {
          visibleTo: [0, 1, 2, 3, 4],
          link: '/employee-list',
        },
      },
    },
    'Employer': {
      visibleTo: [0, 1, 2],
      // icon: <UserAddOutlined />,
      subMenu: {
        // 'Create': {
        //   visibleTo: [0,1,2],
        //   link: '/employer-create',
        // },   
        'List': {
          visibleTo: [0, 1, 2],
          link: '/employer-list',
        },
      },
    },
    'Jobs': {
      visibleTo: [0, 1, 2],
      // icon: <UserAddOutlined />,
      subMenu: {
        'Create': {
          visibleTo: [0, 1, 2],
          link: '/job-post-create',
        },
        'List': {
          visibleTo: [0, 1, 2],
          link: '/job-post-list-all',
        },
      },
    },

    'Complaints': {
      visibleTo: [0, 1, 2],
      // icon: <UserAddOutlined />,
      subMenu: {
        'From Employer': {
          visibleTo: [0, 1, 2],
          link: '/employer-complaint-list',
        },
        'From Employee': {
          visibleTo: [0, 1, 2],
          link: '/employee-complaint-list',

        },
      },
    },

    'Executives': {
      visibleTo: [3],
      // icon: <UserAddOutlined />,
      subMenu: {
        'Executive list': {
          visibleTo: [3],
          link: '/sales-executive-list',
        },
      },
    },

    'Managers': {
      visibleTo: [0],
      // icon: <UserAddOutlined />,
      subMenu: {
        'Manager List': {
          visibleTo: [0],
          link: '/manager-list',
        },
      },
    },
    'Contact Profile': {
      visibleTo: [3, 4],
      // icon: <UserAddOutlined />,
      subMenu: {
        'Contact Profile list': {
          visibleTo: [0, 1, 2, 3, 4],
          link: '/contact-profile-list',
        },
      },
    },
    'Funnel Sheet': {
      visibleTo: [3, 4],
      // icon: <UserAddOutlined />,
      subMenu: {
        'Funnel sheet list': {
          visibleTo: [0, 1, 2, 3, 4],
          link: '/funnel-sheet-list',
        },
      },
    },
    'Disputes': {
      visibleTo: [0],
      // icon: <UserAddOutlined />,
      subMenu: {
        'From employer': {
          visibleTo: [0],
          link: '/employer-job-copmplete-list',
        
        },
        'From employee': {
          visibleTo: [0],
          link: '/employee-job-copmplete-list',
        },
      },
    },

    'Signout': {
      visibleTo: [0, 1, 2, 3, 4],
      // icon: <DashboardOutlined />,
      link: '/',
      onClick: handleLogout,
    },
  };

  // Determine the submenu that contains the active path
  useEffect(() => {
    const activeSubmenu = Object.keys(menuConfig).find((key) => {
      const submenu = menuConfig[key].subMenu;
      return submenu && Object.keys(submenu).some((subKey) => submenu[subKey].link === location.pathname);
    });

    if (activeSubmenu) {
      setOpenKeys([activeSubmenu]); // Set the active submenu as open
    }
  }, [location.pathname]);

  const renderSubMenu = (submenu) => (
    <SubMenu
      key={submenu}
      icon={menuConfig[submenu].icon}
      title={submenu}
    >
      {Object.keys(menuConfig[submenu].subMenu).map((item) =>
        menuConfig[submenu].subMenu[item].visibleTo.includes(userRole) ? (
          <Menu.Item key={menuConfig[submenu].subMenu[item].link}>
            <Link
              to={menuConfig[submenu].subMenu[item].link}
              className={
                location.pathname === menuConfig[submenu].subMenu[item].link
                  ? 'menu-active'
                  : ''
              }
            >
              {item}
            </Link>
          </Menu.Item>
        ) : null
      )}
    </SubMenu>
  );

  return (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={[location.pathname]}
      openKeys={openKeys}
      onOpenChange={handleOpenChange}
    >
      {Object.keys(menuConfig).map((item) =>
        menuConfig[item].visibleTo.includes(userRole) ? (
          menuConfig[item].subMenu ? (
            renderSubMenu(item)
          ) : (
            <Menu.Item
              key={menuConfig[item].link}
              icon={menuConfig[item].icon}
            >
              <Link to={menuConfig[item].link}>{item}</Link>
            </Menu.Item>
          )
        ) : null
      )}
    </Menu>
  );
};

export default SideMenuExecutive;
