

import React, { useState } from 'react';
// import * as EmailValidator from 'email-validator';
export const ROLES = {
    ADMIN: 'admin',
}

export const ENV_CONFIG = {
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
    API_URL: process.env.REACT_APP_API_URL,
    GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API
}

export const REGEX = {
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
}

export const VALIDATION_MSG = {
    emailRequired: 'Please enter a valid Email ID',
    passwordRequired: 'Password is required',
    invalidEmailAddress: 'Invalid email Address',
    passwordLength: 'Password must be at least 8 characters long',
    phoneLength: 'Please enter a valid 10-digit phone number',
    Required: 'Value is eequired',
    phoneRequired: "Please enter a valid Mobile number",
    firstNameRequired: 'First Name required',
    lastNameRequired: 'Last name required',
    addresRequired: 'Company address required',
    passwordMatchError: 'Passwords do not match',
    confirmPasswordRequired: 'Confirm Password is required',
    firstNameContainLettaers: 'First name should contain only letters.',
    lastNameContainLettaers: 'Last name should contain only letters.',
}

export const useValidation = () => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [aadharNum, setAadharNum] = useState('');
    const [aadharError, setAadharError] = useState('');
    const [firstnameError, setfirstnameError] = useState('');
    const [lastnameError, setLastnameError] = useState('');
    const [address, setaddress] = useState('')
    const [addressError, setaddressError] = useState('')

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');   
    const [state, setState] = useState('');
    const [city, setCity] = useState('');


    const validateEmail = () => {
        const emailRegex = REGEX.email
        const isValid = emailRegex.test(email);

        if (!email) {
            setEmailError(VALIDATION_MSG.emailRequired);
        } else if (!isValid) {
            setEmailError(VALIDATION_MSG.invalidEmailAddress);
        } else {
            const afterCom = email.split('.com')[1];
            const containsNumbersAfterCom = /\d/.test(afterCom);
            if (containsNumbersAfterCom) {
                setEmailError('The email after ".com" should not contain numbers.');
                return false;
            }
            setEmailError('');
        }
        return isValid;
    };
    const validatePhone = () => {
        if (!phone) {
            setPhoneError('phone is required.');
        } else if (!(/^\d*$/.test(phone))) {
            setPhoneError('phone number should contain only numbers.');
        } else {
            setPhoneError('');
        }
        return phone;
    }

    // const validatePassword = () => {
    //     if (!password) {
    //         setPasswordError(VALIDATION_MSG.passwordRequired)
    //     } else if (password.length < 6) {
    //         setPasswordError(VALIDATION_MSG.passwordLength);
    //     } else {
    //         setPasswordError('');
    //     }
    //     return password.length >= 6;
    // };

    // const handleEmailBlur = () => {
    //     validateEmail();
    // };

    // const handlePasswordBlur = () => {
    //     validatePassword();
    // };
    const validateAddress = () => {
        if (!address) {
            setaddressError(VALIDATION_MSG.addresRequired);
        } else {
            setaddressError('');
        }
        return address;
    }


    const validateFirstname = () => {
        if (!firstName) {
            setfirstnameError('First name is required.');
        } else if (/\d/.test(firstName)) {
            setfirstnameError('First name should contain only letters.');
        } else {
            setfirstnameError('');
        }
        return firstName;
    }

    const validateLastname = () => {
        if (!lastName) {
            setLastnameError(VALIDATION_MSG.lastnameRequired);
        } else if (/\d/.test(lastName)) {
            setLastnameError('Last name should contain only letters.');
        } else {
            setLastnameError('');
        }
        return lastName;
    }

    const validateAadhar = () => {
        if (!aadharNum) {
            setAadharError(VALIDATION_MSG.aadharRequired);
        } else {
            setAadharError('');
        }
        return aadharNum;
    }


    const validatefield = () => {
        if (!email) {
            setEmailError(VALIDATION_MSG.emailRequired);
        } else {
            setEmailError('');
        }
        return email;
    };

    const validatePassword = () => {
        if (!password) {
            setPasswordError(VALIDATION_MSG.passwordRequired)
        } else if (password.length < 8) {
            setPasswordError(VALIDATION_MSG.passwordLength);
        } else {
            setPasswordError('');
        }
        return password.length >= 8;
    };



    const handlePhoneBlur = () => {
        const phoneRegex = /^\d{10}$/;
        const isValid = phoneRegex.test(phone);

        if (!phone) {
            setPhoneError(VALIDATION_MSG.phoneRequired);
        } else if (!isValid) {
            setPhoneError(VALIDATION_MSG.phoneLength);
        } else {
            setPhoneError('');
        }

        return isValid;
    };

    const validateConfirmPassword = () => {
        if (!confirmPassword) {
            setConfirmPasswordError(VALIDATION_MSG.confirmPasswordRequired);
        } else if (confirmPassword !== password) {
            setConfirmPasswordError(VALIDATION_MSG.passwordMatchError);
        } else {
            setConfirmPasswordError('');
        }

        return confirmPassword === password;
    };
    const handleConfirmPasswordBlur = () => {
        validateConfirmPassword();
    };

    const handleEmailBlur = () => {
        validateEmail();
    };

    const handlePasswordBlur = () => {
        validatePassword();
    };


    const handlefirstnameBlur = () => {

        validateFirstname();
    };
    const handleLastnameBlur = () => {
        validateLastname();
    };
    const handleAdharBlur = () => {
        validateAadhar();
    };
    const handleaddressBlur = () => {
        validateAddress();
    };
    return {
        email,
        setEmail,
        phone,
        emailError,
        handleEmailBlur,
        setPhoneError,
        phoneError,
        setEmailError,
        password,
        setPassword,
        setPhoneError,
        passwordError,
        setPasswordError,
        setPhone,
        handlePasswordBlur,
        phoneError,
        phone,
        setPhone,
        handlePasswordBlur,
        handlePhoneBlur,
        validatefield,
        handleConfirmPasswordBlur,
        confirmPassword,
        setConfirmPassword,
        handlePhoneBlur,
        // validatefield,
        handleConfirmPasswordBlur,
        confirmPassword,
        setConfirmPassword,
        setConfirmPasswordError,
        confirmPasswordError,
        handlefirstnameBlur,
        firstnameError,
        setfirstnameError,
        setFirstName,
        handleLastnameBlur,
        lastnameError,
        setLastnameError,
        setLastName,
        handleAdharBlur,
        aadharError,
        setAadharError,
        aadharNum,
        setAadharNum,
        handleLastnameBlur,
        lastnameError,
        setLastnameError,
        setLastName,
        validateEmail,
        validatePhone,
        address,
        setaddress,
        firstName,
        addressError,
        setaddressError,
        lastName,
        handleaddressBlur
    };
};
