
import React, { useState, useEffect } from 'react';

import Sidebar from '../../components/sidebar';
import PageHeader from '../../components/Header';
import PageFooter from '../../components/Footer';
import Loader from '../../constant/Loader';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import './style.css';
import { Button, TextField, Grid, InputAdornment, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import { useLocation, useNavigate } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';
import { MuiOtpInput } from 'mui-one-time-password-input';
import DoneIcon from '@mui/icons-material/Done';
import { CompanyLogo, EmployerCreate, GovtDoc, sendOtpEmployer, verifyOtpEmployer } from '../../sevices/account';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { isEmptyObj, removeElement } from '../../utils/genericUtilities';
import { useValidation } from '../../constant';
import { notification } from 'antd';

const CreateEmployer = () => {
    const navigate = useNavigate();

    const {
        email,
        setEmail,
        phone,
        validatePhone,
        emailError,
        handleEmailBlur,
        setPhoneError,
        phoneError,
        setConfirmPassword,
        setEmailError,
        password,
        setPassword,
        lastName,
        firstName,
        passwordError,
        setPasswordError,
        setPhone,
        handlePasswordBlur,
        handlePhoneBlur,
        // validatefield,
        handleConfirmPasswordBlur,
        confirmPassword,
        setconfirmPasswordError,
        confirmPasswordError,
        handlefirstnameBlur,
        firstnameError,
        setfirstnameError,
        setFirstName,
        handleLastnameBlur,
        lastnameError,
        setLastnameError,
        setLastName,
        validateEmail,
        address,
        setaddress,
        addressError,
        setaddressError,
        handleaddressBlur

    } = useValidation();


    const [loading, setLoading] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [numberVerified, setNumberVerified] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpResponse, setOtpResponse] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [customMsg, setCustomMsg] = useState({});
    const [companyType, setCompanyType] = useState('');
    const [companyWebsite, setCompanyWebsite] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [aboutCompany, setAboutCompany] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [addSocialMediaLinks, setAddSocialMediaLinks] = useState([{ value: '' }]);
    const [errorPopupOpen, setErrorPopupOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [selectedGovtDoc, setSelectedGovtDoc] = useState(null);
    const [documentType, setDocumentType] = useState('');
    const [documentNumber, setDocumentNumber] = useState('');
    const [referralCode, setReferralCode] = useState('');


    const handleAddMore = () => {
        const values = [...addSocialMediaLinks];
        values.push({ value: '' });
        setAddSocialMediaLinks(values);
    }

    const handleDeleteMore = (index) => {
        if (index > -1) {
            const array = removeElement(addSocialMediaLinks, index);
            setAddSocialMediaLinks(array)
        }

    }

    const handleGovtChange = (event) => {
        setSelectedGovtDoc(event.target.files[0]);
    }
    const handleLogoChange = async (event) => {
        setSelectedFile(event.target.files[0]);

    };
    const handleSocialMediaChange = (index, event) => {

        const values = [...addSocialMediaLinks];
        values[index].value = event.target.value;
        setAddSocialMediaLinks(values);
    };


    const handleCompanyWebsite = (event) => {
        setCompanyWebsite(event.target.value);


    }
    const handleChange = (event) => {
        setCompanyType(event.target.value);

    };

    const handleCompanyAddress = (event) => {
        setaddress(event.target.value);
    }
    const handleChangestate = (event) => {
        setState(event.target.value);
    }
    const handleAboutCompany = (event) => {
        setAboutCompany(event.target.value);
    }

    const handleCity = (event) => {
        setCity(event.target.value);
    }

    const handleEmailChange = (event) => {
        const newEmail = event.target.value;
        setEmail(newEmail);
        //setIsValidEmail(validateEmail(newEmail));
    };

    const validateForm = () => {
        return (
            email &&
            password &&
            firstName &&
            phone &&
            address &&
            lastName &&
            !addressError &&
            !lastnameError &&
            !firstnameError &&
            !confirmPasswordError &&
            !passwordError &&
            !emailError
        );
    };



    const onsubmit = async () => {

        if (!validateForm()) {
            return;
        }

        const body = {}
        body.email = email
        body.password = password
        body.company_name = companyName
        body.about_company = aboutCompany
        body.first_name = firstName
        body.last_name = lastName
        body.company_address = address
        body.zip_code = zipCode
        body.company_type = companyType
        body.company_website = companyWebsite
        body.social_media_link = addSocialMediaLinks
        body.phone = "+91" + phone
        body.username = email
        body.client_id = "CLIENT003"
        body.client_secret = "5PY02KdOpCX5FO93jSjPa7gKsBvQF"
        body.grant_type = "password"
        body.login_type = "password"
        body.verified_document = documentNumber
        body.referral_code = referralCode;

        try {
            const response = await EmployerCreate(body)
            if (response?.status == 200) {

                notification.success({
                    message: "Employer Successfully created",
                })
                navigate("/employer-List")
                let employerId = response?.data?.details?.id;

                if (selectedFile && !isEmptyObj(selectedFile)) {
                    uploadLogo(employerId)
                }

                if (selectedGovtDoc && !isEmptyObj(selectedGovtDoc)) {
                    uploadGovtDoc(employerId)
                }



            }
        } catch (error) {
            console.log("EERDAFC", error);

        }
    }
    const uploadGovtDoc = async (id) => {
        setLoading(true);
        const body = {};
        body.id = 86

        const formData = new FormData();
        formData.append('photo', selectedGovtDoc);
        try {
            const response = await GovtDoc(formData, id);
        } catch (err) {
            setLoading(false);

            console.log('error', err)
        }

    }

    const uploadLogo = async (id) => {
        setLoading(true);
        const body = {};
        body.id = 86

        const formData = new FormData();
        formData.append('photo', selectedFile);
        try {
            const response = await CompanyLogo(formData, id);
            if (response?.status == 200) {
                setLoading(false);
            } else {
            }
        } catch (err) {
            console.log('error', err)
            setLoading(false);
        }

    }

    const handleChangedocumentType = (event) => {
        setDocumentType(event.target.value);
    };

    return (
        <div className="d-flex flex-column flex-root header-fixed">
            <div className="page d-flex flex-row flex-column-fluid">
                <Sidebar />
                <div className="wrapper d-flex flex-column flex-row-fluid w-100 page-layout">
                    <PageHeader />
                    <div className="Page-inner-container">
                        {/* <span>Employer Create</span> */}
                        <div className='row'>
                            <p className='headName'>Employer Create</p>
                        </div>
                        <form>
                            <Grid container spacing={5}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="first_name"
                                        label="First name"
                                        className="form-control"
                                        value={firstName}
                                        defaultValue={firstName}

                                        error={!!firstnameError}
                                        type={'text'}
                                        helperText={firstnameError}
                                        onBlur={handlefirstnameBlur}
                                        onChange={(event) => setFirstName(event.target.value.replace(/[^A-Za-z]/ig, ''))}
                                        InputProps={{
                                            type: 'text',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="last_name"
                                        label="Last name"
                                        className="form-control"
                                        value={lastName}
                                        error={!!lastnameError}
                                        helperText={lastnameError}
                                        onBlur={handleLastnameBlur}
                                        defaultValue={lastName}
                                        onChange={(event) => setLastName(event.target.value.replace(/[^A-Za-z]/ig, ''))}
                                        InputProps={{
                                            type: 'text',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>

                                    <TextField
                                        id="email_id"
                                        label="Email iD"
                                        className="form-control"
                                        value={email}
                                        onBlur={handleEmailBlur}
                                        error={!!emailError}
                                        helperText={emailError}
                                        onChange={handleEmailChange}
                                        // error={!isValidEmail}
                                        //helperText={!isValidEmail ? 'Invalid email format' : ''}
                                        InputProps={{
                                            type: 'text',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="phone_number"
                                        label="Phone number"
                                        className="form-control"
                                        type='number'
                                        onBlur={handlePhoneBlur}
                                        error={!!phoneError}
                                        helperText={phoneError}
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10))}
                                        // style={{display: 'none'}}
                                        InputProps={{
                                            type: 'number',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="password"
                                        label="Password"
                                        className="form-control"
                                        error={!!passwordError}
                                        helperText={passwordError}
                                        value={password}
                                        name='password'
                                        onBlur={handlePasswordBlur}

                                        onChange={(event) => setPassword(event.target.value)}
                                        InputProps={{
                                            type: 'password',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>

                                    <TextField
                                        id="confirm_password"
                                        label="Confirm Password"
                                        className="form-control"
                                        error={!!confirmPasswordError}
                                        helperText={confirmPasswordError}
                                        onBlur={handleConfirmPasswordBlur}
                                        value={confirmPassword}
                                        onChange={(event) => setConfirmPassword(event.target.value)}
                                        InputProps={{
                                            type: 'password',
                                        }}
                                    //error={passwordError}
                                    //helperText={passwordError && 'Passwords do not match'}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>

                                    <TextField
                                        id="company_name"
                                        label="Company name which registerd with government"
                                        className="form-control"
                                        value={companyName}
                                        onChange={(event) => setCompanyName(event.target.value)}
                                        InputProps={{
                                            type: 'text',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="company_website"
                                        label="Company website"
                                        className="form-control"
                                        value={companyWebsite}
                                        onChange={handleCompanyWebsite}
                                        InputProps={{
                                            type: 'text',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="company_address"
                                        label="Company address"
                                        className="form-control"
                                        value={address}
                                        error={!!addressError}
                                        helperText={addressError}
                                        defaultValue={address}
                                        onChange={(event) => setaddress(event.target.value)}
                                        onBlur={handleaddressBlur}
                                        InputProps={{
                                            type: 'text',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>

                                    <FormControl fullWidth>
                                        <InputLabel id="type_of_company">State</InputLabel>
                                        <Select
                                            labelId="state"
                                            id="demo-simple-select"
                                            className="form-menu"
                                            value={state}
                                            label="State"
                                            onChange={handleChangestate}
                                        >
                                            <MenuItem value={'Andhra Pradesh'}>Andhra Pradesh</MenuItem>
                                            <MenuItem value={"Arunachal Pradesh"}>Arunachal Pradesh</MenuItem>
                                            <MenuItem value={"Assam"}>Assam</MenuItem>
                                            <MenuItem value={'Bihar'}>Bihar</MenuItem>
                                            <MenuItem value={'Chhattisgarh'}>Chhattisgarh</MenuItem>
                                            <MenuItem value={'Goa'}>Goa</MenuItem>
                                            <MenuItem value={"Haryana"}>Haryana</MenuItem>
                                            <MenuItem value={"Himachal Pradesh"}>Himachal Pradesh</MenuItem>
                                            <MenuItem value={"Jharkhand"}>Jharkhand</MenuItem>
                                            <MenuItem value={"Karnataka"}>Karnataka</MenuItem>
                                            <MenuItem value={"Kerala"}>Kerala</MenuItem>
                                            <MenuItem value={"Madhya Pradesh"}>Madhya Pradesh</MenuItem>
                                            <MenuItem value={"Maharashtra"}>Maharashtra</MenuItem>
                                            <MenuItem value={"Manipur"}>Manipur</MenuItem>
                                            <MenuItem value={"Meghalaya"}>Meghalaya</MenuItem>
                                            <MenuItem value={"Mizoram"}>Mizoram</MenuItem>
                                            <MenuItem value={"Nagaland"}>Nagaland</MenuItem>
                                            <MenuItem value={"Puducherry"}>Puducherry</MenuItem>
                                            <MenuItem value={"Punjab"}>Punjab</MenuItem>
                                            <MenuItem value={"Rajasthan"}>Rajasthan</MenuItem>
                                            <MenuItem value={"Odisha"}>Odisha</MenuItem>
                                            <MenuItem value={"Sikkim"}>Sikkim</MenuItem>
                                            <MenuItem value={"Tamil Nadu"}>Tamil Nadu</MenuItem>
                                            <MenuItem value={"Telangana"}>Telangana</MenuItem>
                                            <MenuItem value={"Tripura"}>Tripura</MenuItem>
                                            <MenuItem value={"Uttar Pradesh"}>Uttar Pradesh</MenuItem>
                                            <MenuItem value={"Uttarakhand"}>Uttarakhand</MenuItem>
                                            <MenuItem value={"West Bengal"}>West Bengal</MenuItem>
                                            <MenuItem value={"Andaman and Nicobar Islands"}>Andaman and Nicobar Islands</MenuItem>
                                            <MenuItem value={"Chandigarh"}>Chandigarh</MenuItem>
                                            <MenuItem value={"Dadra and Nagar Haveli and Daman and Diu"}>Dadra and Nagar Haveli and Daman and Diu</MenuItem>
                                            <MenuItem value={"Lakshadweep"}>Lakshadweep</MenuItem>
                                            <MenuItem value={"Delhi"}>Delhi</MenuItem>
                                            <MenuItem value={"Ladakh"}>Ladakh</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="city"
                                        label="City"
                                        className="form-control"
                                        value={city}
                                        onChange={handleCity}
                                        InputProps={{
                                            type: 'text',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="pin_code"
                                        label="Pin code"
                                        className="form-control"
                                        value={zipCode}
                                        onChange={(e) =>
                                            setZipCode(e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6))}
                                        // onChange={(event) => {
                                        //     const Zipcode = event.target.value;
                                        //     if (Zipcode.length <= 6) {
                                        //         //setZipCode(Zipcode);
                                        //     }
                                        // }}
                                        InputProps={{
                                            type: 'number',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="type_of_company">Type of company</InputLabel>
                                        <Select
                                            labelId="type_of_company"
                                            id="demo-simple-select"
                                            className="form-menu"
                                            value={companyType}
                                            label="Type of company"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={'Private Limited Company'}>Private Limited Company</MenuItem>
                                            <MenuItem value={"Public Limited Company"}>Public Limited Company</MenuItem>
                                            <MenuItem value={"Partnerships Company"}>Partnerships Company</MenuItem>
                                            <MenuItem value={'Limited Liability Partnership'}>Limited Liability Partnership</MenuItem>
                                            <MenuItem value={'One Person Company'}>One Person Company</MenuItem>
                                            <MenuItem value={'Sole Proprietorship'}>Sole Proprietorship</MenuItem>
                                            <MenuItem value={"Section 8 Company"}>Section 8 Company</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>

                                    <TextField
                                        id="outlined-uncontrolled"
                                        label="Company logo"
                                        className="form-control fileAttachmentInput"
                                        InputLabelProps={{ shrink: true }}
                                        // inputProps={{ accept: "image/*" }}
                                        InputProps={{
                                            type: 'file',
                                            accept: 'image/*', // Accept only JPEG, PNG, and GIF
                                            onChange: handleLogoChange,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <AttachFileIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        id="about_company"
                                        label="About company "
                                        className="form-control"
                                        value={aboutCompany}
                                        onChange={handleAboutCompany}
                                        InputProps={{
                                            type: 'text',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="government-document"
                                        label="Any government authorized document "
                                        className="form-control fileAttachmentInput"
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ accept: "image/*" }}
                                        InputProps={{
                                            type: 'file',
                                            onChange: handleGovtChange,
                                            accept: 'image/*', // Accept only JPEG, PNG, and GIF
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <AttachFileIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {addSocialMediaLinks.map((val, index) => {
                                        return <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <TextField
                                                sx={{ marginTop: '10px' }}
                                                id="social_media_link"
                                                label="Social media link"
                                                className="form-control"
                                                value={val.value}
                                                onChange={(event) => handleSocialMediaChange(index, event)}
                                                InputProps={{
                                                    type: 'text',
                                                    endAdornment: (
                                                        index > 0 ? <InputAdornment position="right">
                                                            <DeleteOutlineOutlinedIcon style={{ cursor: 'pointer' }} className='cursor-pointer'
                                                                onClick={() => handleDeleteMore(index)}
                                                            />
                                                        </InputAdornment> : null
                                                    ),
                                                }}
                                            />
                                        </div>
                                    })}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="type_of_document">Select document for verification *</InputLabel>
                                        <Select
                                            labelId="type_of_document"
                                            id="demo-simple-select"
                                            className="form-menu"
                                            value={documentType}
                                            label="Select document for verification *"
                                            onChange={handleChangedocumentType}
                                        >
                                            <MenuItem value={'Udyog Aadhar'}>Udyog Aadhar</MenuItem>
                                            <MenuItem value={"Udyam"}>Udyam</MenuItem>
                                            <MenuItem value={"GSTIN"}>GSTIN</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {documentType === 'Udyog Aadhar' || documentType === 'Udyam' || documentType === 'GSTIN' ? (
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label={`Enter ${documentType} Number`}
                                            variant="outlined"
                                            value={documentNumber}
                                            onChange={(e) => setDocumentNumber(e.target.value)}
                                            inputProps={{
                                                maxLength: 15,
                                            }}
                                        />
                                    </Grid>
                                ) : null}

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="referral Code"
                                        label="Referral code"
                                        className="form-control"
                                        value={referralCode}
                                        onChange={(event) => setReferralCode(event.target.value.replace(/[^0-9]/ig, ''))}
                                        inputProps={{
                                            inputMode: 'numeric',
                                            maxLength: 6

                                        }}
                                    />
                                </Grid>


                                <Grid item xs={12} sm={12} className="addMoreItemBtn">
                                    <Button
                                        onClick={handleAddMore}
                                    >Add more +</Button>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <div className="WizardNextStep">
                                        <Button disabled={!validateForm()} style={{ marginRight: "20px", backgroundColor: 'green' }} onClick={onsubmit} variant="contained" className="WizardNextBtn" >
                                            <span color='green' >Submit</span>

                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </form>

                    </div>
                    <PageFooter />
                </div>
            </div>
            <Loader loading={loading} />
        </div>

    );

}
export default CreateEmployer;