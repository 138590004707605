import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Button, Form, notification, Modal, Input, Tag } from 'antd'
import './style.css';
import {SalesExecutiveStatus, SalesExecutiveView } from '../../sevices/account';
import PageHeader from '../../components/Header';
import Sidebar from '../../components/sidebar';
import PageFooter from '../../components/Footer';
import Loader from '../../constant/Loader';
import { useNavigate } from 'react-router-dom';

const { TextArea } = Input;

const ViewSalesExecutive = (props) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [getStatusText, setStatusText] = useState('');
    const [getViewAdminData, setViewAdminData] = useState({});
    const [loading, setLoading] = useState(false);

    const queryParams = new URLSearchParams(window.location.search);
    const viewID = queryParams.get('id');


    useEffect(() => {
        getViewEmployer()
    }, []);

    const getViewEmployer = async () => {
        setLoading(true)
        const params = {}
        params.id = viewID
        try {

            const response = await SalesExecutiveView(params);
            if (response?.status === 200) {
                setViewAdminData(response);
                setLoading(false)
                form.setFieldsValue({
                    first_name: response?.data?.details?.first_name,
                    last_name: response?.data?.details?.last_name,
                    email: response?.data?.details?.email,
                    phone: response?.data?.details?.phone,
                    employer_target: response?.data?.details?.employer_target,
                    EmployerCount: response?.data?.details?.EmployerCount,
                    part_timer_target: response?.data?.details?.part_timer_target,
                    EmployeeCount: response?.data?.details?.EmployeeCount,
  
                })
            }
        } catch (error) {
			setLoading(false)

            console.log("Error:", error);
        }
    };


    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 50);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        if (!_.isEmpty(getStatusText)) {
            let body = {}
            body.id = viewID
            body.status_glossary = getStatusText
            body.status = getViewAdminData?.data?.details?.status == 1 ? 0 : 1
            try {
                const response = await SalesExecutiveStatus(body)
                console.log('admin_status_data', response);
                if (response?.data?.details?.status == 1) {
                    setIsModalOpen(false);
                    notification.success({
                        message: "Sales executive Successfully Activated",
                    })
                    navigate("/sales-executive-List")
                } else {
                    setIsModalOpen(false);
                    notification.success({
                        message: "Sales Executive Successfully Deactivated",
                    })
                    navigate("/sales-executive-List")
                }
            } catch (error) {
			setLoading(false)

                console.log('admin_status_error', error);

                notification.error({
                    message: "Error",
                    description: "Please input your Reason"
                })
            }
        } else {
            notification.error({
                message: "Error",
                description: "Please input your Reason"
            })
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const changeStatusGlossory = (e) => {
        setStatusText(e.target.value);
    }


    const render = () => {
        return (
            <div className="d-flex flex-column flex-root header-fixed">
                <div className="page d-flex flex-row flex-column-fluid">
                    <Sidebar />
                    <div className="wrapper d-flex flex-column flex-row-fluid w-100 page-layout">
                        <PageHeader />
                        <div className="Page-inner-container">
                            <div>
                                <div className="page-inner-container">
                                    <Form form={form}>

                                        <div className='template-list-title'>
                                            <span style={{ fontSize: '22px', fontWeight: '600' }}>Sales Executive View</span>
                                        </div>
                                        <div className="row">


                                            <div className="col-12 col-md-12 col-sm-12">
                                                <label>
                                                    First Name
                                                </label>

                                                <Form.Item name='first_name' rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your First Name!',
                                                    },
                                                ]}>
                                                    <Input readOnly={true} className="form-input" />
                                                </Form.Item>

                                            </div>
                                            <div className="col-12 col-md-12 col-sm-12">
                                                <label>
                                                    Last Name
                                                </label>
                                                <Form.Item name='last_name' rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your Last Name!',
                                                    },
                                                ]}><Input readOnly={true} className="form-input" /></Form.Item>
                                            </div>
                                            <div className="col-12 col-md-12 col-sm-12">
                                                <label>
                                                    Email
                                                </label>
                                                <Form.Item name='email' rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your Email!',
                                                    },
                                                ]}><Input readOnly={true} className="form-input" /></Form.Item>

                                            </div>
                                            <div className="col-12 col-md-12 col-sm-12">
                                                <label>
                                                    Phone Number
                                                </label>
                                                <Form.Item name='phone' rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your phone!',
                                                    },
                                                ]}><Input readOnly={true} className="form-input" /></Form.Item>
                                            </div>

                                            <div className="col-12 col-md-12 col-sm-12">
                                                <label>
                                                   Employer Target
                                                </label>
                                                <Form.Item name='employer_target' rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your Employer target!',
                                                    },
                                                ]}><Input readOnly={true} className="form-input" /></Form.Item>
                                            </div>
                                            <div className="col-12 col-md-12 col-sm-12">
                                                <label>
                                                   Employer Achived
                                                </label>
                                                <Form.Item name='EmployerCount' rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your Employer target!',
                                                    },
                                                ]}><Input readOnly={true} className="form-input" /></Form.Item>
                                            </div><div className="col-12 col-md-12 col-sm-12">
                                                <label>
                                                Part Timer Target
                                                </label>
                                                <Form.Item name='part_timer_target' rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your Employer target!',
                                                    },
                                                ]}><Input readOnly={true} className="form-input" /></Form.Item>
                                            </div><div className="col-12 col-md-12 col-sm-12">
                                                <label>
                                                   Part Timer Achived
                                                </label>
                                                <Form.Item name='EmployeeCount' rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your Employer target!',
                                                    },
                                                ]}><Input readOnly={true} className="form-input" /></Form.Item>
                                            </div>



                                            <div className="col-6">
                                                <label>
                                                    Status
                                                </label>

                                                <Form.Item>
                                                    <div style={{ display: "flex", alignItems: "center" }}>

                                                        <Tag style={{ marginRight: "20px" }} color={_.get(getViewAdminData, 'data.details.status') == 1 ? "green" : "red"}>{_.get(getViewAdminData, 'data.details.status') == 1 ? "Active" : "Deactive"}</Tag>

                                                        <Button className={_.get(getViewAdminData, 'data.details.status') == 1 ? "btn-danger" : "btn-success"} style={{ lineHeight: '15px' }} onClick={() => showModal()}>{_.get(getViewAdminData, 'data.details.status') == 1 ? "Deactivate" : "Activate"}</Button>
                                                    </div>
                                                </Form.Item>
                                            </div>



                                        </div>

                                    </Form>

                                    <Modal title={_.get(getViewAdminData, 'data.details.status') == 1 ? "Reason for Deactivate" : "Reason for Activate"} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                                        <TextArea onChange={(e) => changeStatusGlossory(e)} rows={4} placeholder="Reason for changing status" />
                                    </Modal>
                                </div>
                            </div>
                        </div>
                        <PageFooter />
                    </div>
                </div>
                <Loader loading={loading} />
            </div>


        );
    };

    return render();
};



export default ViewSalesExecutive;