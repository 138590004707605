import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import { ContactProfileTargetApi, jobPostCount, UserCount } from '../../sevices/account';

const useStyles = makeStyles({
    root: {
        minWidth: 250,
        maxWidth: 370,
        minHeight: 160,
        maxHeight: 280,
        margin: '10px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    numericValue: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    smallText: {
        fontSize: 14,
    },
});

const blue = "rgb(241, 244, 255)";
const pink = "rgb(252, 222, 234)";
const yellow = "rgb(253, 243, 229)";
const pink2 = "rgb(255, 229, 246)";


const CardsData = ({ target, numericValue1, smallText1, smallText2, numericValue2, backgroundColor }) => {
    const classes = useStyles();

    return (
        <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card variant="outlined" className={classes.root} style={{ backgroundColor }}>
                <CardContent>
                    <Typography className={classes.title} gutterBottom>
                        {target}
                    </Typography>
                    <Typography variant="h5" component="p" className={classes.numericValue}>
                        {numericValue1}
                    </Typography>
                    <Typography variant="body2" component="p" className={classes.smallText}>
                        {smallText1}
                    </Typography>

                    <Typography variant="h5" component="p" className={classes.numericValue}>
                        {numericValue2}
                    </Typography>
                    <Typography variant="body2" component="p" className={classes.smallText}>
                        {smallText2}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    );
}

const DshboardData = () => {
    // ==================Posts Data======================

    const classes = useStyles();
    const [postCount, setPostCount] = useState("");
    const [userCount, setUserCount] = useState("");
    const [targetAchievedData, setTargetAchievedData] = useState(null);
    const [personTarget, setPersonTarget] = useState(null);


    const getJobPostCount = async () => {
        try {
            const response = await jobPostCount({});
            if (response?.status === 200) {
                setPostCount(response?.data);
            }
        } catch (error) {
            console.log("Error:", error);
        }
    };

    useEffect(() => {
        getJobPostCount();
    }, []);

    // ==================Sasles Data======================



    async function fetchData() {
        try {
            const targetAchieved = await ContactProfileTargetApi();
            setTargetAchievedData(targetAchieved.data.target_achieved);
            setPersonTarget(targetAchieved.data.set_target);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []); 


    const rate = 4130;

    // ==================== Users  Data===========================
  
    const userCountData = async () => {
        try {
            const response = await UserCount({});
            if (response?.status === 200) {
                setUserCount(response?.data);
            }
        } catch (error) {
            console.log("Error:", error);
        }
    };

    useEffect(() => {
        userCountData();
    }, []);


    return (
        <Box>

            {/* ========================== Sales Data =================== */}

        
            <Grid container spacing={1} className={classes.cardContainer}>
                <CardsData
                    target={<span style={{ fontSize: '20px' }}>Sales Target</span>}
                    numericValue1={personTarget}
                    smallText1="Units"
                    numericValue2={personTarget * rate}
                    smallText2="Revenue"
                    backgroundColor={blue}
                />
                <CardsData
                    target={<span style={{ fontSize: '20px' }}>Target Achieved</span>}
                    numericValue1={targetAchievedData}
                    smallText1="Units"
                    numericValue2={rate * targetAchievedData}
                    smallText2="Revenue"
                    backgroundColor={pink}
                />
                <CardsData
                    target={<span style={{ fontSize: '20px' }}>Over all Sales</span>}
                    numericValue1={targetAchievedData}
                    smallText1="Units"
                    numericValue2={rate * targetAchievedData}
                    smallText2="Revenue"
                    backgroundColor={yellow}
                />
            </Grid>

            {/* ========================== Posts Data =================== */}
         
            <Grid container spacing={2} className={classes.cardContainer}>
                <CardsData
                    target={<span style={{ fontSize: '20px' }}>Today's</span>}
                    numericValue1={postCount?.details?.jobPostsCountPerDay}
                    smallText1="Posts"
                    backgroundColor={blue}
                />
                <CardsData
                    target={<span style={{ fontSize: '20px' }}>Weekly</span>}
                    numericValue1={postCount?.details?.jobPostsCountPerWeek}
                    smallText1="Posts"
                    backgroundColor={pink}
                />
                <CardsData
                    target={<span style={{ fontSize: '20px' }}>Last 6 Month</span>}
                    numericValue1={postCount?.details?.jobPostsCountForSixMonth}
                    smallText1="Posts"
                    backgroundColor={yellow}
                />
            </Grid>

            {/* ========================== Users Data =================== */}
         
            <Grid container spacing={2} className={classes.cardContainer}>
                <CardsData
                    target={<span style={{ fontSize: '20px' }}>Total </span>}
                    numericValue1={userCount?.total_employee}
                    smallText1="Part Timers "
                    backgroundColor={blue}
                />
            
                <CardsData
                    target={<span style={{ fontSize: '20px' }}>Total </span>}
                    numericValue1={userCount?.total_employer}
                    smallText1="Employers"
                    backgroundColor={pink}
                />
                <CardsData
                    target={<span style={{ fontSize: '20px' }}>Active </span>}
                    numericValue1={userCount?.active_employer}
                    smallText1="Employers"
                    backgroundColor={yellow}
                />
            </Grid>
        </Box>
    );
}

export default DshboardData;
