export const ACCOUNT_API_URL = {
  login: "/oauth/token",
  employerList: "employer/list",
  employerListExcel: "employer/list-excel",
  userCount: "admins/users-count",
  employerView: "employer/view",
  employerStatusUpdate: "employer/status",
  SalesExecutiveStatusUpdate: "/sales-executive/status",
  employeelist: "employee/list",
  employeeListExcel: "employee/list-excel",
  employeeStatus: "employee/status",
  employeeView: "employee/view",
  jobPostCount: "job-post/post-counts",
  userLocation: "dashboard/users-location",
  // employeeCreate: "admins/create",
  employeeProfile: "employee/update-photo-profile",
  jobPostList: "job-post/list-with-employee",
  markAsPaid: "job-post/mark-as-paid",
  // bankDetails: "job-post/bank-detail",
  JobCategoryList: "/job-category/list",
  JobCertificationList: "/job-certification/list",
  JobQualificationsList: "/qualifications/list",
  JobSkillList: "/skills/list",
  CreateNewSkills: "/skills/create",

  employerCreate: "admins/admin-employer-create",
  sendOtpEmployer: "/employer/otp-send",
  verifyOtpEmployer: "/employer/otp-verify",
  govtDoc: "/admin-employer/update-photo-govt",
  companyLogo: "/admin-employer/update-photo-logo",
  jobPostListAll: "job-post/list-all",
  statusChange: "job-post/status-admin",
  // salesExecutiveList:'/manager/v1/list',
  salesExecutiveView: "sales-executive/view",
  // ExecutiveCreate: "/sales-executive/create",
  ExecutiveCreate: "/sales-executive/v1/create",
  
  ExecutiveUpdate: "/sales-executive/v1/update",  
  TargetUpdate: "/sales-executive/update-target",
  TargetView: "/sales-executive/view-target",
  employerComplaintList: "/employer-complaint/list",
  employeeComplaintList: "/employee-complaint/list",
  employeeComplaintJobView: "/employee-complaint/view",
  employerComplaintJobView: "/employer-complaint/view",
  employerComplaintStatus: "/employer-complaint/change-status",
  employeeComplaintStatus: "/employee-complaint/change-status",
  // salesExecutiveListApi: "/sales-executive/list",
  salesExecutiveListApi: "/sales-executive/v1/list",
  salesExecutiveViewApi: "/sales-executive/v1/view",
  salesExecutiveUpdateApi: "/sales-executive/v1/update",

  managerCreateApi: "/manager/v1/create",
  managerViewApi: "/manager/v1/view",
  managerUpdateApi: "/manager/v1/update",
  managerListApi: "/manager/v1/list",
  employeeListApi: "/employee/v1/list",
  
  contactProfileCreateApi: "/contact-profile/v1/create",
  contactProfileUpdateApi: "/contact-profile/v1/update",
  contactProfileViewApi: "/contact-profile/v1/view",
  contactProfileListApi: "/contact-profile/v1/list",
  contactProfileTargetApi: "/contact-profile/v1/target",
  funnelSheetListApi: "/contact-profile/v1/funnel-list",
  // funnelSheetListApi: "/contact-profile/v1/list",
  funnelSheetViewApi: "/contact-profile/v1/view",
  funnelSheetUpdateApi: "/contact-profile/v1/update",
  jobStatusEmployerListApi : '/job-applications/employer-job-incomplete-list',
  jobStatusEmployeeListApi : '/job-post/employee-job-incomplete-list',
  jobPostView: "/job-post/view",
  CreateNewCertification: '/job-certification/create',
  CreateNewQualification: '/qualifications/create',
  UpdateJob: '/job-post/update',
  postJob: 'job-post/create-by-admin',
  viewJob: '/job-post/view',






};
