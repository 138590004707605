import React, { useState, useEffect } from 'react';
import { Table, Button } from 'antd';
import { jobStatusEmployeeListApi } from '../../sevices/account';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../components/Header/index';
import PageFooter from '../../components/Footer/index';
import Sidebar from '../../components/sidebar/index';
import './style.css';
import Loader from '../../constant/Loader';


const userColumns = [
  {
    title: 'Part-timer Name',
    dataIndex: 'first_name',
    key: 'first_name',
  },
  {
    title: 'Part-timer Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Job Id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Job Name',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Action',
    dataIndex: 'actions',
    key: 'actions',
    align: 'center',
  },
];

const EmployeeJobComplete = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchItem, setSearchItem] = useState('');
  const [metaData, setMetaData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [jobPostData, setJobPostData] = useState([]);

  useEffect(() => {
    fetchList(currentPage);
  }, [searchItem]);

  const fetchList = async (page) => {
    setLoading(true);
    const params = {
      user_type: 'employee',
      employee_tag: 2,
      search: searchItem,
      page,
      limit: 10,
    };

    try {
      const response = await jobStatusEmployeeListApi(params);
      if (response?.status === 200) {
        const jobMeta = response?.data?.meta;
        setMetaData(jobMeta || {});
        setJobPostData(response?.data?.items || []);
      }
    } catch (error) {
      console.error("Error fetching subscribed employees:", error);
    } finally {
      setLoading(false);
    }
  };

  let tableSourceDatasubscribed = [];
  if (_.size(jobPostData) > 0) {
    tableSourceDatasubscribed = jobPostData.map((value) => ({
      first_name: value.employee_profile?.first_name || '-',
      email: value.employee_profile?.email || '-',  
      id: value?.id || '-',
      // Ensure you're accessing email from the correct nested object
      title: value.title ? value.title.charAt(0).toUpperCase() + value.title.slice(1) : '',
      last_name: value.last_name ? value.last_name.charAt(0).toUpperCase() + value.last_name.slice(1) : '-',
      created_at: value.created_at ? new Date(value.created_at).toLocaleDateString('en-GB') : '',
      city: value.city || '',
      status: value.status === 1 ? <span className='table-paid-status'>Active</span> : value.status === 0 ? <span className='table-status-cancelled'>Inactive</span> : '',
      employee_tag: value.employee_tag === 2 ? <span className='table-status-cancelled'>Pending</span> : value.employee_tag === 9 ? <span className='table-paid-status'>Complete</span> : '',
      actions: <Button type='text' onClick={() => navigate(`/job-complete-view?id=${_.get(value, 'id')}`)}>View</Button>,
    }));
  }

  const renderTableSubscribed = (data) => {
    return (
      <Table
        scroll={{ y: "calc(100vh - 100px)" }}
        columns={userColumns}
        dataSource={data}
        pagination={{
          current: currentPage,
          pageSize: 10,
          showSizeChanger: false,
          total: metaData?.total || 0,
          onChange: (page) => {
            setCurrentPage(page);
            fetchList(page);
          },
        }}
      />
    );
  };

  return (
    <div className="d-flex flex-column flex-root header-fixed">
      <div className="page d-flex flex-row flex-column-fluid">
        <Sidebar />
        <div className="wrapper d-flex flex-column flex-row-fluid w-100 page-layout">
          <PageHeader />
          <div className="Page-inner-container">
            <div className='template-list-title'>
              <span style={{ fontSize: '22px', fontWeight: '600' }}>From Part-Timers</span>
            </div>
            {renderTableSubscribed(tableSourceDatasubscribed)}
          </div>
          <PageFooter />
        </div>
      </div>
      <Loader loading={loading} />
    </div>
  );
};

export default EmployeeJobComplete;
