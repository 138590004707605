
import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/sidebar';
import PageHeader from '../../components/Header';
import PageFooter from '../../components/Footer';
import Loader from '../../constant/Loader';
import { Button, TextField, Grid} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {  TargetUpdate, TargetView } from '../../sevices/account';
import { notification } from 'antd';

const TargetCreate = () => {
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(window.location.search);
    const viewID = queryParams.get('id');
    const [EmployerTarget, setEmployerTarget] = useState(0);
    const [partTimerTarget, setPartTimerTarget] = useState(0);

    const [loading, setLoading] = useState(false);
 
    useEffect(() => {
        if (viewID) {
            getViewTarget()

        }
    }, []);

    const getViewTarget = async () => {
        setLoading(true)
        const params = {}
        params.id = viewID
        try {

            const response = await TargetView(params);

            if (response?.status === 200) {

                setLoading(false)
                setEmployerTarget(response?.data?.details?.employer_target)
                setPartTimerTarget(response?.data?.details?.part_timer_target)

            }
        } catch (error) {
            setLoading(false)

            console.log("Error:", error);
        }
    };


    const onUpdate = async () => {

        const body = {}
        body.id = viewID
        body.employer_target = EmployerTarget   
        body.part_timer_target = partTimerTarget
   

        try {
            const response = await TargetUpdate(body)

            if (response?.status == 200) {

                notification.success({
                    message: "Target Successfully updated",
                })
                navigate("/target-update")
       



            }
        } catch (error) {
            console.log("EERDAFC", error);

        }
    }

    return (
        <div className="d-flex flex-column flex-root header-fixed">
            <div className="page d-flex flex-row flex-column-fluid">
                <Sidebar />
                <div className="wrapper d-flex flex-column flex-row-fluid w-100 page-layout">
                    <PageHeader />
                    <div className="Page-inner-container">

                        <div className='row'>
                            <p className='headName'>Target Update</p>
                        </div>
                        <form>
                            <Grid container spacing={5}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="target"
                                        label="Employer Target"
                                        className="form-control"
                                        value={EmployerTarget}
                                        defaultValue={EmployerTarget}
                                        type={'text'}
                                        onChange={(event) => setEmployerTarget(event.target.value.replace(/[^0-9]/g, ''))}
                                        InputProps={{
                                            type: 'text',
                                        }}
                                        inputProps={{
                                            maxLength: 8
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="partTimerTarget"
                                        label="Part Timer Target "
                                        className="form-control"
                                        value={partTimerTarget}
                                        defaultValue={partTimerTarget}
                                        onChange={(event) => setPartTimerTarget(event.target.value.replace(/[^0-9]/g, ''))}
                                        InputProps={{
                                            type: 'text',
                                        }}
                                        inputProps={{
                                            maxLength: 8
                                        }}
                                    />
                                </Grid>
                        
                            
                                <Grid item xs={12} sm={12}>
                                    <div className="WizardNextStep">
                                        
                                        <Button style={{ marginRight: "20px", backgroundColor: 'green' }} onClick={onUpdate} variant="contained" className="WizardNextBtn" >
                                            <span color='green' >Update</span>
                                        </Button> 
                                       

                                    </div>
                                </Grid>
                            </Grid>
                        </form>

                    </div>
                    <PageFooter />
                </div>
            </div>
            <Loader loading={loading} />
        </div>

    );

}
export default TargetCreate;